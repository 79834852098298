// import Datafeeds from "@js/charting_library/datafeed/udf/datafeed.js";
export default {
  props: ["loading"],
  data() {
    return {
      option: {
        autosize: true,
        symbol: "BINANCE:BTCUSDT",
        interval: "1",
        timezone: "Asia/Tehran",
        theme: "dark",
        style: "1",
        locale: "en",
        toolbar_bg: "#f1f3f6",
        enabled_features: [
          "header_fullscreen_button",
          "side_toolbar_in_fullscreen_mode",
          // "header_in_fullscreen_mode",
        ],
        disabled_features: [
          "use_localstorage_for_settings",
          "volume_force_overlay",
        ],
        enable_publishing: false,
        withdateranges: true,
        range: "YTD",
        hide_side_toolbar: false,
        allow_symbol_change: true,
        calendar: true,
        container_id: "tradingview_54dbc",
      },
    };
  },
  computed: {
    chartPair() {
      return this.$route.params.pair.replace("btcb", "btc").toUpperCase().replace("_", "");
      // return this.$route.params.pair.includes("btcb")
      //   ? `BTC${this.$route.params.pair.split("_")[1].toUpperCase()}`
      //   : this.$route.params.pair.toUpperCase().replace("_", "");
    },
  },
  mounted() {
    this.option.symbol = this.chartPair;
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://s3.tradingview.com/tv.js";
    script.id = "tradingViewID";
    document.head.appendChild(script);
    setTimeout(() => {
      new window.TradingView.widget(this.option);
      setTimeout(() => {
        document.getElementById("tradingview_wrapper").classList.add("d-block");
        this.$emit("chartsLoading");
      }, 2000)
    }, 2000);
    // setTimeout(() => {
    //   require(["@js/charting_library/charting_library.min.js"], function(tv) {
    //     // widget.onChartReady(() => {
    //     //   widget.headerReady().then(() => {
    //     //     const button = widget.createButton();
    //     //
    //     //     button.setAttribute('title', 'Click to show a notification popup');
    //     //     button.classList.add('apply-common-tooltip');
    //     //
    //     //     button.addEventListener('click', () => widget.showNoticeDialog({
    //     //       title: 'Notification',
    //     //       body: 'TradingView Charting Library API works correctly',
    //     //       callback: () => {
    //     //         // eslint-disable-next-line no-console
    //     //         console.log('Noticed!');
    //     //       },
    //     //     }));
    //     //
    //     //     button.innerHTML = 'Check API';
    //     //   });
    //     // });
    //   });
    // }, 500);
  },
  methods: {},
};
